/* Code to handle the Session Timeout function. How it works?
  Every globally defined period of time (see meta[name="timeout"]) we show -
  just before the actual session expires on the server - the session timeout modal
  to reenter credentials.

  However we also check every 30 seconds if there is any user activity by registering keypresses.
  We then 'poke' the server to extend the session timeout; after which we have to
  reinitialize the interval handlers.
*/

// LOG function to be active in demo mode only
var LOG = window.demo_mode ? console.log.bind(console) : function () {};

/* Load */
var zv_session_timeout_handler_interval;
var zv_session_timeout_max;
var zv_session_timeout_is_busy= false;
var zv_session_timeout_logged_out=true;
var zv_session_timeout_activity_interval;

window.addEventListener('load', function(event) {
  zv_session_timeoutInit();
});

window.addEventListener('keypress', function(event) {
  if (!zv_session_timeout_is_busy) LOG('Keypressed');
  zv_session_timeout_is_busy= true;
});

/* Init */
function zv_session_timeoutInit() {
  if (typeof zv_session_timeout_handler_interval==='number') clearTimeout(zv_session_timeout_handler_interval);
  if (typeof zv_session_timeout_activity_interval==='number') clearInterval(zv_session_timeout_activity_interval);

  zv_session_timeout_max= $('meta[name="timeout"]').attr('content')
  if (zv_session_timeout_max) {
    const zv_session_timeout_activity_loop_period= (zv_session_timeout_max > 60000 ? 60000 : 25000)                           // Run every 1 minute unless the timeout itself is set on 1 minute
    zv_session_timeout_max= zv_session_timeout_max-5;
    zv_session_timeout_handler_interval= setTimeout(zv_session_timeoutHandler, zv_session_timeout_max*1000);                  // 30 minutes
    zv_session_timeout_activity_interval= setInterval(zv_session_timeoutActivity, zv_session_timeout_activity_loop_period);   // 60 seconds or less
  }
  zv_session_timeout_is_busy= false;
}

/* This function checks periodically (1 minute) if the user pressed a key and will Poke the server to extend the session time */
function zv_session_timeoutActivity() {
  if (zv_session_timeout_is_busy) {
    LOG('[Timeout] Call poke');
    zv_session_timeout_poke();
    zv_session_timeoutInit();
  }
}

/* This function shows the Session Timeout modal popup and handles its triggers. */
function zv_session_timeoutHandler() {
  if (window.HtmlViewer || (window.webkit && window.webkit.messageHandlers)) {
    // Do not log out yet, this is before the actual logout. TODO callback to app to show notification about to log out
    // window.HtmlViewer['logOut']();
    return;
  }

  // Get and show the popup unless it is already visible
  if (!$('#timeout_modal:visible').length) {
    LOG('[Timeout] Call timeout');

    zv_session_timeout_logged_out= true;
    if (typeof zv_session_timeout_activity_interval==='number') {
      clearInterval(zv_session_timeout_activity_interval);
    }

    // Here we fetch the Popup HTML code
    Rails.ajax({
      type: 'get',
      url: '/timeout',
      dataType: 'script',
      success: function(data) {
        const popup_container= document.querySelector('#js_popup')
        if (popup_container) {
          popup_container.innerHTML= data.html;

          $('#timeout_modal:hidden', popup_container).modal('show');

          // Set focus on password field when Session timeout popup appears
          $('#timeout_modal').on('shown.bs.modal', function (e) {
            // $('#person_password', $('#timeout_modal')).focus();
            document.querySelector('body').dataset.timeout='true'
            $("#timeout_modal input[type!='hidden'][type!='submit'][type!='reset']:enabled").first().focus();
            var customEvent = new CustomEvent('custom::ajax:success', {
              detail: {
              }});
            document.dispatchEvent(customEvent);
          });

          // Force page refresh when popup window was hidden without any submit
          $('#timeout_modal').on('hidden.bs.modal', function (e) {
            zv_session_timeoutInit();
            if (zv_session_timeout_logged_out)  {
              window.location.reload();
            } else {
              document.querySelector('body').dataset.timeout='false'
            }
          });

          init_knock_form();
        }
      }
    });
  }
}

function zv_session_timeout_poke() {
  $.getJSON('/poke', function(data) {
    LOG('[Timeout] poke => ');
    LOG(data);
  })
}

/* NOTE: This function is called from other places as well! */
function init_knock_form() {
  /* Password relogin submit */
  $('#mfa_form form').submit(function(event) {
    /* Notice that we want to login, hence do not refresh to force logout */
    zv_session_timeout_logged_out= false;
  });
}

window.init_knock_form = init_knock_form